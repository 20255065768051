<template>
  <div class="message_content">
    <page-content>
      <search-panel
          flex
          show-btn
          :is-entry="false"
          @getList="getList"
        >
        <div>
          <p>投诉类型</p>
          <!-- <el-cascader
            placeholder="请选择投诉类型"
            v-model="violationId"
            :props="{value: 'id', label: 'violationName'}"
            :options="typeList"
            size="mini"
          >
          </el-cascader> -->
          <el-select
              v-model="violationId"
              clearable
              size="mini"
              placeholder="请选择投诉类型"
            >
              <el-option
                v-for="(item,index) in typeList"
                :key="index"
                :label="item.violationName"
                :value="item.id"
              />
            </el-select>
        </div>
        <div>
          <p>用户名称</p>
          <el-input
            v-model.trim="nickName"
            clearable
            size="mini"
            placeholder="请输入用户名称"
          />
        </div>
        <div>
          <p>投诉工单号</p>
          <el-input
            v-model.trim="id"
            clearable
            size="mini"
            placeholder="请输入投诉工单号"
          />
        </div>
        <div>
          <p>订单号</p>
          <el-input
            v-model.trim="orderNo"
            clearable
            size="mini"
            placeholder="请输入订单号"
          />
        </div>
        <div>
          <p>创建时间</p>
          <el-date-picker
            v-model="timeData"
            size="mini"
            type="datetimerange"
            align="right"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :default-time="['00:00:00', '23:59:59']"
          >
          </el-date-picker>
        </div>
      </search-panel>
    </page-content>
    <page-content>
      <!-- 列表 -->
      <el-table
        v-loading="loading"
        border
        :header-cell-style="{'background': '#F0F2F8', 'color': '#333'}"
        :data="tableData"
        element-loading-text="拼命加载中"
        style="width:100%;"
      >
        <el-table-column
          prop="id"
          label="工单号"
        />
        <el-table-column
          prop="violationName"
          label="投诉类型"
        />
        <el-table-column
          prop="createTime"
          label="创建时间"
        >
          <template slot-scope="scope">
            {{ dayFormat(scope.row.createTime) }}
          </template>
        </el-table-column>
        <el-table-column
          prop="nickName"
          label="用户名称"
        />
        <el-table-column
          prop="orderNo"
          label="订单号"
        />
        <el-table-column
          prop="goodsName"
          label="商品"
        />
        <el-table-column
          prop="content"
          label="操作"
        >
          <template slot-scope="scope">
            <el-button type="text" size="mini" @click="toDetail(scope.row)">详情</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :current-page="pagination.currentPage"
        :page-sizes="[10, 20, 30, 50]"
        :page-size="pagination.nowPageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="pagination.count"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </page-content>
    <el-dialog
      title="投诉详情"
      :visible.sync="dialogVisible"
      width="50%"
      :before-close="handleClose">
      <el-form label-width="80px">
        <el-form-item label="描述">
          <el-input type="textarea" disabled v-model="form.content"></el-input>
        </el-form-item>
        <el-form-item label="图片">
          <!-- <img :src="item" v-for="(item,index) in form.images" alt="" :key="index" style="width: 100px; height: 100px;"> -->
          <el-image
            style="width: 100px; height: 100px"
            :src="item"
            v-for="(item,index) in form.images"
            :key="index"
            :preview-src-list="form.images">
          </el-image>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import SearchCard from '@/components/common/SearchCard'
import dayjs from 'dayjs'

export default {
  name: 'MessageList',
  components: {
    SearchCard
  },
  data() {
    return {
      violationId: '', // 投诉类型
      ids: [],
      loading: true,
      tableData: [],
      pagination: {
        currentPage: 1,
        nowPageSize: 10,
        count: 0
      },
      startTime: '',
      endTime: '',
      timeData: [],
      dialogVisible: false,
      form:{
        content:'',
        images: []
      },
      nickName: '',
      orderNo:'',
      id: '',
      typeList:[]
    }
  },
  watch: {
    timeData(item) {
      if (item) {
        this.startTime = Date.parse(new Date(item[0]))
        this.endTime = Date.parse(new Date(item[1]))
      } else {
        this.startTime = null
        this.endTime = null
      }
    }
  },
  mounted() {
    this.getList()
    this.getTypeList()
  },
  methods: {
    getRowKey(row) {
      return row.id
    },
    dayFormat(val) {
      return dayjs(parseInt(val)).format('YYYY/MM/DD HH:mm:ss')
    },
    handleSizeChange(val) {
      this.pagination.nowPageSize = val
      this.pagination.currentPage = 1
      this.getList()
    },
    handleCurrentChange(val) {
      this.pagination.currentPage = val
      this.getList()
    },
    changeStatus(item) {
      this.status = item.value
      this.getList()
    },
    // 列表数据
    getList(index) {
      this.loading = true
      this.$axios.post(this.$api.feedback.list, {
          currentPage: this.pagination.currentPage,
          pageSize: this.pagination.nowPageSize,
          nickName: this.nickName,
          violationId: this.violationId,
          // violationId: this.violationId[this.violationId.length -1],
          orderNo: this.orderNo,
          startTime: this.startTime ? this.startTime : undefined,
          endTime: this.endTime ? this.endTime : undefined,
          id: this.id
      }).then((res) => {
        let r = res
        if (r.code === 0) {
          const { size, total, current, pages } = r.data
          this.pagination = {
            currentPage: Number(current),
            nowPageSize: Number(size),
            count: Number(total)
          }
          this.tableData = r.data.records
        } else {
          this.tableData = []
        }
        this.loading = false
      })
    },
    handleClose(){
      this.dialogVisible = false
    },
    getTypeList(){
      this.$axios.get(this.$api.feedback.typeList, {
        params: {}
      }).then((res) => {
        let r = res
        if (r.code === 0) {
          r.data.forEach(item => {
            item.children = item.child;
          })
          this.typeList = r.data
        } else {
          this.typeList = []
        }
        console.log(this.typeList,'======')
      })
    },
    toDetail(row){
      this.dialogVisible = true
      this.form.content = row.content
      this.form.images = row.imageUrls.split(',')
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
