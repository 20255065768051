var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "message_content" },
    [
      _c(
        "page-content",
        [
          _c(
            "search-panel",
            {
              attrs: { flex: "", "show-btn": "", "is-entry": false },
              on: { getList: _vm.getList },
            },
            [
              _c(
                "div",
                [
                  _c("p", [_vm._v("投诉类型")]),
                  _c(
                    "el-select",
                    {
                      attrs: {
                        clearable: "",
                        size: "mini",
                        placeholder: "请选择投诉类型",
                      },
                      model: {
                        value: _vm.violationId,
                        callback: function ($$v) {
                          _vm.violationId = $$v
                        },
                        expression: "violationId",
                      },
                    },
                    _vm._l(_vm.typeList, function (item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: { label: item.violationName, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                [
                  _c("p", [_vm._v("用户名称")]),
                  _c("el-input", {
                    attrs: {
                      clearable: "",
                      size: "mini",
                      placeholder: "请输入用户名称",
                    },
                    model: {
                      value: _vm.nickName,
                      callback: function ($$v) {
                        _vm.nickName =
                          typeof $$v === "string" ? $$v.trim() : $$v
                      },
                      expression: "nickName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                [
                  _c("p", [_vm._v("投诉工单号")]),
                  _c("el-input", {
                    attrs: {
                      clearable: "",
                      size: "mini",
                      placeholder: "请输入投诉工单号",
                    },
                    model: {
                      value: _vm.id,
                      callback: function ($$v) {
                        _vm.id = typeof $$v === "string" ? $$v.trim() : $$v
                      },
                      expression: "id",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                [
                  _c("p", [_vm._v("订单号")]),
                  _c("el-input", {
                    attrs: {
                      clearable: "",
                      size: "mini",
                      placeholder: "请输入订单号",
                    },
                    model: {
                      value: _vm.orderNo,
                      callback: function ($$v) {
                        _vm.orderNo = typeof $$v === "string" ? $$v.trim() : $$v
                      },
                      expression: "orderNo",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                [
                  _c("p", [_vm._v("创建时间")]),
                  _c("el-date-picker", {
                    attrs: {
                      size: "mini",
                      type: "datetimerange",
                      align: "right",
                      "start-placeholder": "开始日期",
                      "end-placeholder": "结束日期",
                      "default-time": ["00:00:00", "23:59:59"],
                    },
                    model: {
                      value: _vm.timeData,
                      callback: function ($$v) {
                        _vm.timeData = $$v
                      },
                      expression: "timeData",
                    },
                  }),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _c(
        "page-content",
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              staticStyle: { width: "100%" },
              attrs: {
                border: "",
                "header-cell-style": { background: "#F0F2F8", color: "#333" },
                data: _vm.tableData,
                "element-loading-text": "拼命加载中",
              },
            },
            [
              _c("el-table-column", { attrs: { prop: "id", label: "工单号" } }),
              _c("el-table-column", {
                attrs: { prop: "violationName", label: "投诉类型" },
              }),
              _c("el-table-column", {
                attrs: { prop: "createTime", label: "创建时间" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(_vm.dayFormat(scope.row.createTime)) +
                            " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "nickName", label: "用户名称" },
              }),
              _c("el-table-column", {
                attrs: { prop: "orderNo", label: "订单号" },
              }),
              _c("el-table-column", {
                attrs: { prop: "goodsName", label: "商品" },
              }),
              _c("el-table-column", {
                attrs: { prop: "content", label: "操作" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.toDetail(scope.row)
                              },
                            },
                          },
                          [_vm._v("详情")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c("el-pagination", {
            attrs: {
              "current-page": _vm.pagination.currentPage,
              "page-sizes": [10, 20, 30, 50],
              "page-size": _vm.pagination.nowPageSize,
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.pagination.count,
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.handleCurrentChange,
            },
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "投诉详情",
            visible: _vm.dialogVisible,
            width: "50%",
            "before-close": _vm.handleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            { attrs: { "label-width": "80px" } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "描述" } },
                [
                  _c("el-input", {
                    attrs: { type: "textarea", disabled: "" },
                    model: {
                      value: _vm.form.content,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "content", $$v)
                      },
                      expression: "form.content",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "图片" } },
                _vm._l(_vm.form.images, function (item, index) {
                  return _c("el-image", {
                    key: index,
                    staticStyle: { width: "100px", height: "100px" },
                    attrs: { src: item, "preview-src-list": _vm.form.images },
                  })
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }